<template>
  <div class="record-category-block">
    <div class="image-wrapper" v-if="item.wiki_article && item.wiki_article.image">
      <router-link :to="getCategoryRoute(item)">
        <img :src="item.wiki_article.image" height="200" width="352" />
      </router-link>
    </div>
    <div class="content-wrapper">
      <div>
        <router-link :to="getCategoryRoute(item)" class="text-lg bold">{{ item.name }}</router-link>
        <div
          v-html="item.wiki_article.content"
          v-if="item.wiki_article && item.wiki_article.content"
          class="wiki-content text-sm"
        ></div>
      </div>

      <div class="stats text-sm">
        <div class="stat-item" v-if="item.books_count">
          <div class="title">Books</div>
          <div class="value">{{ item.books_count.toLocaleString('en') }}</div>
        </div>
        <div class="stat-item" v-else>
          <div class="title">Records</div>
          <div class="value">{{ item.persons_count ? item.persons_count.toLocaleString('en') : '' }}</div>
        </div>
        <div class="stat-item bordered">
          <div class="title">Language</div>
          <div class="value">{{ item.languages }}</div>
        </div>
        <div class="stat-item">
          <div class="title">Countries</div>
          <div class="value">
            <span v-for="country in item.source_countries" :key="country.id" class="name">{{ country.pinyin }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SEARCH_TAB_BY_DEFAULT_FORM} from '@common/utils/consts.search';

export default {
  props: {
    item: Object,
    surname: {type: String, required: false},
  },
  methods: {
    getCategoryRoute(item) {
      const tab = SEARCH_TAB_BY_DEFAULT_FORM[item.default_form];
      const query = {category_id: item.id, tab};
      return {name: 'search-all-records', query};
    },
  },
  name: 'RecordCategoryBlock',
};
</script>

<style lang="scss" scoped>
.record-category-block {
  background: $background-light;
  border-radius: 4px;
  overflow: hidden;

  .content-wrapper {
    padding: 14px 16px 16px;
  }

  .image-wrapper {
    height: 200px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    box-shadow: $box-shadow;

    a {
      display: block;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .wiki-content {
    margin-top: 8px;
    color: $neutral-600;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .stats {
    margin-top: 6px;

    .stat-item {
      color: $neutral-600;
      display: flex;
      justify-content: space-between;
      padding: 10px 0 8px;

      .title {
        font-weight: 500;
        min-width: 130px;
      }

      .value {
        text-align: end;
      }

      .name:not(:last-child)::after {
        content: ', ';
      }

      &:last-of-type {
        padding-bottom: 0;
      }
    }

    .stat-item.bordered {
      border-top: 1px solid $neutral-200;
      border-bottom: 1px solid $neutral-200;
    }
  }
}
</style>
